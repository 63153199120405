img {
	max-width: 100%; /* Make sure images are scaled correctly. */
	height: auto; /* Adhere to container width. */
	vertical-align: top;
}

figcaption {
	padding: 16px 0 0;
	text-align: center;
	font-size: .9em;
}

[style*="--lazy-image-height"]:before {
	display: block;
	width: 100%;
	content: "";
	height: 0;
	padding-top: var(--lazy-image-height);
}

.moonfit-lazy-image {
	position: relative;
	overflow: hidden;
	background-color: #e2e2e2;
	max-width: 100%;
	width: var(--lazy-image-width);

	&:before {
		transform: translateX(-100%);
		background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, .2) 20%, rgba(#fff, .5) 60%, rgba(#fff, 0) 100%);
		animation: skeleton-loading 1s cubic-bezier(.4, 0, .2, 1) infinite;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.ll-background.ll-background-unload {
	position: relative;
	background-color: #e2e2e2 !important;
	background-image: none !important;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background: linear-gradient(90deg, rgba(#fff, 0) 0%, rgba(#fff, .2) 20%, rgba(#fff, .5) 60%, rgba(#fff, 0) 100%);
		animation: skeleton-loading 1s cubic-bezier(.4, 0, .2, 1) infinite;
	}
}
