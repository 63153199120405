.slider-screenshot {
	.swiper-inner {
		margin: -20px -20px -30px;
	}

	.swiper-container {
		padding: 20px 20px 30px;
	}
}

.image-frame-on-hover {
	@extend %NormalTransition;
	position: relative;
	display: block;
	border-radius: 24px;

	&:before {
		@extend %NormalTransition;
		content: '';
		position: absolute;
		top: -2px;
		left: -2px;
		right: -2px;
		bottom: -2px;
		border-radius: inherit;
		background: linear-gradient(135deg, #4ccbc9 0%, #b24294 100%);
		opacity: 0;
	}

	img {
		position: relative;
		z-index: 1;
		display: block;
		width: 100%;
		border-radius: inherit;
	}

	&:hover {
		box-shadow: 0 4px 30px rgba(161, 107, 216, .5);

		&:before {
			opacity: 1;
		}
	}
}

@include media-breakpoint-up(md) {
	.slider-screenshot {
		.swiper-pagination-container {
			display: none;
		}
	}
}
