$primary-color: #4ccbc9;
$secondary-color: #e4007b;
$twitter-color: #479ce9;

// $body-font-family: 'Darker Grotesque', sans-serif;
$body-font-family: 'Race Sport', sans-serif;
$body-font-weight: 700;
$body-font-weight-secondary: 600;
$body-color: #a8adc3;
$body-bg-color: #21063c;
$heading-font-family: 'Race Sport', sans-serif;
$heading-font-weight: 400;
$heading-font-weight-secondary: 400;
$heading-color: #fff;
$link-color: #a8adc3;
$link-hover-color: $primary-color;

$button-rounded: 10px;
$button-font-family: $body-font-family;
$button-font-size: 18px;
$button-font-weight: 800;
$button-letter-spacing: 0;
$button-text-transform: uppercase;
$button-text: #020722;
$button-background: #fff;
$button-border: #fff;
$button-hover-text: #020722;
$button-hover-background: $primary-color;
$button-hover-border: $primary-color;

$form-font-family: $body-font-family;
$form-font-weight: $body-font-weight;
$form-font-size: 15px;
$form-line-height: 26px;
$form-input-border-thickness: 1px;
$form-input-padding: 18px;
$form-input-height: 50px;
$form-input-rounded: 5px;
$form-textarea-rounded: 5px;
$form-text: #000;
$form-border: #d2d2d2;
$form-background: #fff;
$form-shadow: none;
$form-focus-text: #000;
$form-focus-border: #000;
$form-focus-background: #fff;
$form-focus-shadow: none;

// Alert
$success-color: #6cbd7e;
$error-color: #d9534f;
$warning-color: #ff9800;
$info-color: #2196f3;

$box-small-rounded: 0;

$box-background: rgba(15, 21, 51, 0.7);
$box-hover-background: rgba(2, 7, 34, 0.7);

$button-box-shadow: 0px 4px 0px 0px #ab005c, 0px 0px 44px 0px rgba(134,14,255,1);
$button-box-shadow-2: 0px 0px 44px 0px rgba(134,14,255,1);