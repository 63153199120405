.lw-reward-container {
    // height: 100vh;
    height: 100%;
    background-size: cover;
    background: url(../../assets/images/background/reward-bg.png) no-repeat center;
    // padding: 0 20px;
    overflow: hidden;
    position: relative;
    .lw-reward {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 10;
        transform: translateY(-50%);
        img {
            width: 65%;
            margin: 20px auto 0;
            user-select: none;
        }
    }

    .lw-btn-back {
        top: 50px;
        right: 20px;
        position: absolute;
    }
    .lw-reward-title {
        padding-top: 160px;
        p {
            font-family: "Poppins", sans-serif;
            line-height: 26px;
            margin: 20px 0 -20px;
            text-transform: initial;
        }
    }
    .lw-reward-wrapper {
        height: calc(100vh - 270px);
        padding: 0 20px;
        position: relative;
        .aura {
            width: 85%;
            margin: 0 auto;
            animation: zoom-in-zoom-out 2s ease-in-out infinite;
        }
        .explode {
            top: 0;
            left: 50%;
            position: absolute;
        }
        .lw-button {
            margin-top: -20px;
            .button {
                width: 100%;
                font-size: 13px;
                line-height: 20px;
                font-weight: 600;
                text-transform: initial;
                border-radius: 7px;
                font-family: "Poppins", sans-serif;
            }
        }
    }
}
.beyond,
.paris-dot {
    .lw-reward-container {
        background-size: cover !important;
        width: 100vw;
    }

    .aura {
        opacity: 0;
    }
}

.beyond {
    .lw-reward-container {
        background: url(../../assets/images/background/beyond-reward-bg.png) no-repeat center;
    }
}

.paris-dot {
    .lw-reward-container {
        background: url(../../assets/images/background/paris-dot-reward-bg.png) no-repeat center;
    }
}
.polkadot {
    .lw-reward-container {
        background-size: cover !important;
        width: 100vw;
        background: url(../../assets/images/background/polkadot-reward-bg.png) no-repeat center;
        .lw-reward-title {
            padding: 0;
            h3,
            p {
                font-family: "Unbounded", sans-serif;
            }
            h3 {
                font-weight: 900 !important;
            }
            p {
                padding: 0 34px;
            }
        }
        .lw-reward {
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            img {
                width: 70%;
            }
        }
        .lw-button {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            // background: #21063c;
            padding: 20px;
            .button {
                width: 100%;
                font-size: 13px;
                line-height: 20px;
                font-weight: 600;
                text-transform: initial;
                border-radius: 7px;
                font-family: "Poppins", sans-serif;
            }
        }
    }
}

.moonbox-out-slot-container {
    .ant-modal-close {
        display: none;
    }
    .ant-modal-content {
        border: none !important;
        box-shadow: none !important;
        background: transparent !important;
        &::before {
            content: none !important;
        }
        .ant-modal-body {
            padding: 0 !important;
        }
    }
    .reward-moonbox-wrapper,
    .buy-slot-wrapper {
        position: relative;
        border-radius: 10px;
        border: 0px solid transparent;
        background-clip: padding-box;
        background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
        box-shadow: 0 4px 50px 0 rgba(161, 107, 216, 0.5);
        .border-gradient {
            width: 100%;
            height: 100%;
            background-color: #351c4f;
            border-radius: 10px;
            padding: 20px;
        }
    }
    .reward-moonbox-wrapper {
        img {
            width: 72px;
            height: 72px;
            user-select: none;
        }
    }
    .buy-slot-wrapper {
        .mfr {
            width: 22px;
            height: 22px;
        }
        .grid {
            .button {
                padding-top: 3px;
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                text-transform: initial;
                border-radius: 7px;
                font-family: "Poppins", sans-serif;
                &.button-purge {
                    color: #fff;
                    background-color: transparent;
                    border: 2px solid rgba(255, 255, 255, 0.14);
                }
            }
        }
    }
}

.reward-modal-container {
    top: 0 !important;
    padding: 0 !important;
    max-width: calc(100vw) !important;
    width: 100% !important;
    background-size: cover !important;
    background: url(../../assets/images/background/reward-bg.png) no-repeat center !important;
    .ant-modal-content {
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        padding: 0 !important;
        // background: transparent !important;
        &::before {
            content: none !important;
        }

        top: 0;
        margin: 0;
        width: 100%;
        height: 100%;

        .ant-modal-close {
            display: none;
        }
        .ant-modal-body {
            // width: 100vw;
            height: 100vh;
            padding: 0 !important;
        }
    }
}

@media (max-width: 376px) {
    .polkadot {
        .lw-reward-container {
            .lw-reward {
                top: 40%;
            }
        }
    }
}

@media (max-width: 390px) {
    .lw-reward-container {
        .lw-reward-title {
            padding-top: 120px;
        }
        .lw-reward-wrapper {
            height: calc(100vh - 240px);
        }
    }
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.25, 1.25);
    }
    100% {
        transform: scale(1, 1);
    }
}
