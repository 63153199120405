@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500;600;700;800&display=swap');
@import "../assets/fonts/race-sport/font-race-sport.css";
@import "../assets/fonts/poppin/font-poppin.css";
@import "../assets/fonts/unbounded/font-unbounded.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "bootstrap/bootstrap-nav.css";
@import "settings/all";
@import "tools/all";
@import "generic/normalize";
@import "vendor/bootstrap/bootstrap-grid";
@import "elements/all";
@import "components/all";
@import "pages/all";
