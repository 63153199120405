/**
 * Font Awesome code
 */
//$fa-x: '\f1fd';

$far-arrow-right: '\f061';
$far-arrow-left: '\f060';
$fa-angle-left: '\f104';
$fa-angle-right: '\f105';
$fa-angle-up: '\f106';
$fa-angle-down: '\f107';
$fal-comment-dots: '\f4ad';
$fa-long-arrow-left: '\f177';
$fa-long-arrow-right: '\f178';
$fa-star: '\f005';
$fa-exchange: '\f0ec';
$fa-eye: '\f06e';
$fa-eye-slash: '\f070';
$fa-times: '\f00d';
$fa-plus: '\f067';
$fa-minus: '\f068';
$fa-question-circle: '\f059';
$fa-share-alt: '\f1e0';
$fa-spinner-third: '\f3f4';
$fa-search: '\f002';
$fa-trash-alt: '\f2ed';
$fa-upload: '\f093';
$fa-camera: '\f030';
$fa-bars: '\f0c9';
$fa-palette: '\f53f';
$fa-life-ring: '\f1cd';
$fa-book: '\f02d';
$fa-shopping-bag: '\f290';
$fa-user: '\f007';
$fa-check: '\f00c';
$fa-calendar: '\f133';
$fa-exclamation-circle: '\f06a';
$fa-info-circle: '\f05a';
$fa-check-circle: '\f058';
$fa-bell: '\f0f3';
$fa-fire: '\f06d';
$fa-bolt: '\f0e7';
$fa-clock: '\f017';
$fa-pencil: '\f040';
$fa-envelope: '\f0e0';
$fa-map-marker-alt: '\f3c5';
$fa-phone-alt: '\f879';

// Brands
$fa-facebook-f: '\f39e';
$fa-twitter: '\f099';
$fa-google: '\f1a0';
$fa-instagram: '\f16d';
$fa-linkedin: '\f08c';
$fa-amazon: '\f270';
$fa-vk: '\f189';
$fa-microsoft: '\f3ca';
$fa-yahoo: '\f19e';
$fa-apple: '\f179';
$fa-paypal: '\f1ed';
$fa-wordpress: '\f19a';
$fa-pinterest: '\f0d2';
