.color-white {
  color: #ffffff;

}

.home-page {
  margin-top: 50px;

  .ant-spin-container {
    font-family: sans-serif;
  }

  .ant-list-item {
    text-transform: none;
  }
}
