.block-frame {
	@extend %NormalTransition;
	position: relative;
	border-radius: 10px;
}

.block-frame-border {
	position: absolute;
	top: var(--outline-size, 0);
	right: var(--outline-size, 0);
	bottom: var(--outline-size, 0);
	left: var(--outline-size, 0);
	pointer-events: none;
	border-radius: inherit;
	overflow: hidden;

	svg {
		width: 100%;
		height: 100%;
	}
}

.block-frame-content {
	position: relative;
	z-index: 1;
}
