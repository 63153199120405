.moonfit-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 39px auto 0;

  .moonfit-card-inner {
    //background-color: #020722;
    background-color: #1c0532;
    width: 100%;
    padding: 30px 30px;

    $border: 3px;
    color: #fff;
    position: relative;
    background-clip: padding-box;
    border: solid $border transparent;
    border-radius: 10px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: linear-gradient(to right, #4ccbc9, #e4007b);
    }

    .card-title {
      font-family: 'Race Sport', serif;
      color: #4ccbc9;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
    }

    .card-body {
      padding: 0;
      max-width: 100%;
      margin: 0 auto;
      text-align: left;

      .card-body-row {
        padding: 16px 22px;
        background-color: #2A1143;
        border-radius: 7px;

        .card-body-row-title {
          color: #A8ADC3;
          font-size: 16px;
        }

        .card-body-separator {
          color: #90829d;
          opacity: 0.2;
          //border: 1px solid #90829D;
          margin: 20px 0 3px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .moonfit-card {
    .moonfit-card-inner {
      padding: 15px;
    }
  }
}