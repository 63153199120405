@mixin NormalTransition {
	transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

%NormalTransition {
	@include NormalTransition();
}

@mixin FastTransition {
	transition: all .15s cubic-bezier(.645, .045, .355, 1);
}

%FastTransition {
	@include FastTransition();
}

@mixin SlowTransition {
	transition: all .5s cubic-bezier(.645, .045, .355, 1);
}

%SlowTransition {
	@include SlowTransition();
}

@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

%clearfix {
	@include clearfix;
}

@mixin absolute {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

%Absolute {
	@include absolute();
}

%ContentAbsolute {
	content: '';
	@include absolute();
}

// Mixin for link transition

@mixin LinkTransition {
	--gutter-y: 4px;
	position: relative;
	display: inline-block;
	line-height: 1;
	padding: 5px 0;

	&:after {
		content: '';
		width: 0;
		height: 1px;
		position: absolute;
		left: auto;
		right: 0;
		bottom: var(--gutter-y);
		transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
		background: currentColor;
	}

	&:hover {
		&:after {
			width: 100%;
			left: 0;
			right: auto;
		}
	}
}

%LinkTransition {
	@include LinkTransition();
}

@mixin LinkTransition02 {
	--gutter-y: 4px;
	position: relative;
	display: inline-block;
	line-height: 1;
	padding: 5px 0;

	&:before,
	&:after {
		content: "";
		position: absolute;
		bottom: var(--gutter-y);
		left: 0;
		width: 100%;
		height: 1px;
		background: currentColor;
		transform-origin: right;
		transform: scaleX(1) translateZ(0);
		transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s, -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
	}

	&:after {
		transform-origin: left;
		transform: scaleX(0) translateZ(0);
		transition: transform .6s cubic-bezier(.165, .84, .44, 1);
	}

	&:hover {
		&:before {
			transform: scaleX(0) translateZ(0);
			transition: transform .6s cubic-bezier(.165, .84, .44, 1);
		}

		&:after {
			transform: scaleX(1) translateZ(0);
			transition: transform .6s cubic-bezier(.165, .84, .44, 1) .3s, -webkit-transform .6s cubic-bezier(.165, .84, .44, 1) .3s;
		}
	}
}

%LinkTransition02 {
	@include LinkTransition02();
}

@mixin FontMoonfitFA {
	font-family: MoonfitFa !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%FontMoonfitFA {
	@include FontMoonfitFA();
}

// Mixin for font awesome version 5.x

@mixin FontAwesome {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

%FontAwesomeSolid {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
}

%FontAwesomeLight {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}

%FontAwesomeRegular {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
}

%FontAwesomeBrands {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}

%FontAwesomeSolidImportant {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 900;
}

%FontAwesomeLightImportant {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

%FontAwesomeRegularImportant {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;
}

%FontAwesomeBrandsImportant {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Brands' !important;
	font-weight: 400;
}

@mixin LightCloseCursor {
	cursor: url('assets/images/cursor/light-close.png') 16 16, pointer;
}

%LightCloseCursor {
	@include LightCloseCursor();
}

/* Bottom line waving */

@mixin BottomLineWaving {
	position: relative;
	padding-bottom: 10px;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		width: 40px;
		height: 5px;
		-webkit-mask-image: url(assets/svg/union.svg);
		mask-image: url(assets/svg/union.svg);
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: center center;
		mask-position: center center;
		background-color: #000;
	}
}

%BottomLineWaving {
	@include BottomLineWaving();
}
