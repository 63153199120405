.countdown-item {
    margin: 10px 0;
    position: relative;
    .countdown {
        background: #e4007b;
        border-radius: 7px;
        width: 48px;
        height: 48px;
        text-align: center;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 44px 0px #860eff, inset 0px -2px rgba(0, 0, 0, 0.25);
        .countdown-number {
            h3 {
                color: #ffffff;
                font-size: 20px;
                // line-height: 48px;
                width: inherit;
                height: inherit;
                display: flex;
                align-items: center;
            }
        }
    }
    .countdown-title {
        font-size: 11px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        line-height: 18px;
    }
    &:not(:last-child):after {
        content: ":";
        position: absolute;
        top: 12px;
        right: -2px;
        color: #ffffff;
        font-family: "Race Sport";
    }
}
