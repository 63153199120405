.weekly-raffle-wrapper {
    background-color: #15134b;
    &.ajuna {
        background-color: unset;
    }
}
.weekly-raffle-container {
    text-transform: initial;
    color: #ffffff;
    padding-bottom: 30px;
    height: 100vh;
    background-size: cover !important;
    background: url(../../assets/images/background/weekly-raffle-bg-1.jpg) no-repeat center;
    &.astar-degen-event {
        background: url(../../assets/images/background/special-weekly-raffle-bg.png) no-repeat center;
        .weekly-raffle-banner {
            padding-top: 30px;
            img {
                width: 100%;
            }
        }
        .weekly-raffle-content {
            margin-top: 0;
            .weekly-raffle-banner-title {
                .special-prize-bg {
                    // background: linear-gradient(270deg, #ff0393 0%, #9925a2 40.97%, #5447cc 74.57%, #02cde0 99.88%);
                    // transform: skew(-15deg);
                    // margin: 0 20px 20px;
                    // padding: 2px 0;
                    p {
                        transform: skew(15deg);
                    }
                    img {
                        width: 100%;
                    }
                }
                .text-astar-gradient {
                    background: #05c7df;
                    background: linear-gradient(to right, #05c7df 0%, #fa0493 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .daily-countdown {
            padding: 20px 50px;
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: url(../../assets/images/background/uncommon-winner-aura.png) no-repeat center;
                width: 100%;
                height: 250px;
                z-index: 0;
            }
            .round-winner {
                .nft-winner {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .uncommon-winner-border,
                    .common-winner-border {
                        position: relative;
                        border: 2px solid;
                        border-radius: 50%;
                        padding: 6px;
                        width: 94px;
                        height: 94px;
                        margin: 0 auto;
                        background-color: #351c4f;
                        img {
                            border-radius: 50%;
                        }
                    }
                    .winner-avatar {
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        border: 1.5px solid #ffffff;
                        border-radius: 50%;
                    }
                    .uncommon-winner-border {
                        border-color: #48c8f0;
                        .winner-avatar {
                            bottom: 5px;
                            right: -10px;
                        }
                    }
                    .common-winner-border {
                        border-color: #efaa5d;
                        transform: scale(0.7);
                        .winner-avatar {
                            transform: scale(1.3);
                            right: -10px;
                            bottom: 0px;
                        }
                    }
                    .winner-crown {
                        position: absolute;
                        top: -10px;
                        left: -10px;
                    }
                    .winner-info {
                        p {
                            white-space: nowrap;
                            width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    &.apes-event,
    &.mones-event {
        .weekly-raffle-banner {
            img {
                width: 100%;
            }
        }
        .weekly-raffle-content {
            margin-top: 0;
            padding-top: 20px;
            .weekly-raffle-banner-title {
                .special-prize-bg {
                    p {
                        transform: skew(15deg);
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 235px;
                z-index: 0;
                opacity: 0.8;
            }
            .round-winner {
                .nft-winner {
                    .uncommon-winner-border,
                    .common-winner-border {
                        position: relative;
                        border: 2px solid;
                        border-radius: 50%;
                        padding: 6px;
                        width: 94px;
                        height: 94px;
                        margin: 0 auto;
                        background-color: #351c4f;
                        img {
                            border-radius: 50%;
                        }
                    }
                    .uncommon-winner-border {
                        border-color: #efaa5d;
                        .winner-avatar {
                            bottom: 5px;
                            right: -10px;
                        }
                    }
                    .winner-crown {
                        position: absolute;
                        top: -10px;
                        left: -10px;
                    }
                    .winner-info {
                        p {
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    &.welcome-raffle {
        height: 50vh;
        padding-top: 50px;
        background: url(../../assets/images/background/welcome-raffle-bg.png) no-repeat center;
        .weekly-raffle-banner {
            padding-top: 30px;
            img {
                width: 100%;
            }
        }
    }
    &.apes-event {
        height: 85vh;
        background: url(../../assets/images/background/apes-bg.jpg) no-repeat center;
        .weekly-raffle-banner {
            padding-top: 30px;
        }
    }
    &.mones-event {
        height: 80vh;
        background: url(../../assets/images/background/mones-bg.png) no-repeat center;
        .weekly-raffle-banner {
            padding-top: 50px;
        }
        .weekly-raffle-content {
            margin-top: -30px !important;
            padding-top: 0 !important;
            .special-prize-bg {
                margin: 0 auto 10px;
                max-width: 120px;
            }
        }
        .finished-round-container {
            &::before {
                height: 265px;
            }
            .round-winner {
                .nft-winner {
                    margin: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .common-winner-border {
                        transform: scale(0.9);
                        border-color: rgba(239, 170, 93, 1);
                        &.active-user {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
    &.ajuna-event {
        height: 85vh;
        background: url(../../assets/images/background/ajuna-bg.png) no-repeat center;
        .weekly-raffle-banner {
            padding-top: 50px;
        }
        .weekly-raffle-content {
            margin-top: -30px !important;
            padding-top: 0 !important;
            .special-prize-bg {
                margin: 0 auto 10px;
                max-width: 120px;
            }
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 235px;
                z-index: 0;
                opacity: 0.8;
            }
            .round-winner {
                .nft-winner,
                .nft-winners {
                    margin: 0 20px;
                    .ajuna-winner-border,
                    .common-winner-border {
                        position: relative;
                        border: 2px solid;
                        border-radius: 50%;
                        padding: 6px;
                        width: 64px;
                        height: 64px;
                        margin: 0 auto;
                        background-color: #351c4f;
                        img {
                            border-radius: 50%;
                        }
                    }
                    .common-winner-border {
                        transform: scale(0.9);
                        border-color: rgba(239, 170, 93, 1);
                        &.active-user {
                            transform: scale(1.1);
                        }
                    }
                    .ajuna-winner-border {
                        transform: scale(0.9);
                        border-color: #b8ac9e;
                        &.active-user {
                            transform: scale(1.1);
                        }
                    }
                    .winner-info {
                        p {
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .nft-winner {
                    display: block;
                    .common-winner-border,
                    .ajuna-winner-border {
                        margin-bottom: 20px;
                        transform: scale(1.2);
                    }
                }
                .nft-winners {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
        }
    }
    &.algem-raffle,
    &.hashkey-raffle,
    &.summer-fitsnap-raffle {
        height: 58vh;
        background: url(../../assets/images/background/summer-fitsnap-bg.png) no-repeat center;
        .weekly-raffle-banner {
            img {
                width: 100%;
            }
        }
        .finished-round-container {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 250px;
                z-index: 0;
            }
            .round-winner {
                .nft-winner {
                    .uncommon-winner-border {
                        position: relative;
                        border: 2px solid #e4007b;
                        border-radius: 50%;
                        padding: 6px;
                        width: 94px;
                        height: 94px;
                        margin: 0 auto;
                        background-color: #351c4f;
                        box-shadow: 0px 0px 12px rgba(228, 0, 123, 0.35);
                        img {
                            border-radius: 50%;
                        }
                    }
                    .winner-info {
                        p {
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    &.algem-raffle,
    &.hashkey-raffle,
    &.starfish-raffle,
    &.astrid-raffle,
    &.lunar-raffle,
    &.christmas-raffle,
    &.halloween-raffle,
    &.valentine-raffle,
    &.yuliverse-raffle {
        .instruction-container {
            .instruction-item {
                padding: 42px 15px 15px 15px !important;
                .instruction-item-index {
                    left: 15px !important;
                }
            }
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 235px;
                z-index: 0;
                opacity: 0.8;
            }
            .round-winner {
                .nft-winner,
                .nft-winners {
                    margin: 0 20px;
                    .other-winner-border,
                    .common-winner-border {
                        position: relative;
                        border: 2px solid;
                        border-radius: 50%;
                        padding: 6px;
                        width: 64px;
                        height: 64px;
                        margin: 0 auto;
                        background-color: #351c4f;
                        img {
                            border-radius: 50%;
                        }
                    }
                    .common-winner-border {
                        transform: scale(0.9);
                        border-color: #efaa5d;
                        &.active-user {
                            transform: scale(1.1);
                        }
                    }
                    .other-winner-border {
                        transform: scale(0.9);
                        border-color: #57c2ff;
                        &.active-user {
                            transform: scale(1.1);
                        }
                    }
                    .winner-info {
                        p {
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .nft-winner {
                    display: block;
                    .common-winner-border,
                    .ajuna-winner-border {
                        margin-bottom: 20px;
                        transform: scale(1.2);
                    }
                }
                .nft-winners {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
            }
        }
    }
    &.algem-raffle {
        height: 70vh;
        padding-top: 150px;
        background: url(../../assets/images/background/algem-background.png) no-repeat center;
        .text-algem-title {
            font-family: "Unbounded", sans-serif;
            font-weight: 700;
            font-size: 25px;
            line-height: 36px;
            color: #57c2ff;
        }
    }
    &.hashkey-raffle {
        height: 100vh;
        padding-top: 80px;
        background: url(../../assets/images/background/hashkey-background.png) no-repeat center;

        .daily-countdown {
            background: rgba(53, 28, 79, 1) !important;
            border: 1px solid rgba(53, 28, 79, 1) !important;
        }
    }
    &.starfish-raffle {
        height: 100vh;
        padding-top: 80px;
        background: url(../../assets/images/background/starfish-background.png) no-repeat center;

        .daily-countdown {
            background: rgba(53, 28, 79, 1) !important;
            border: 1px solid rgba(53, 28, 79, 1) !important;
        }
        .weekly-raffle-content {
            .weekly-raffle-banner-title {
                margin-top: 300px;
            }
        }
    }
    &.astrid-raffle {
        height: 85vh;
        padding-top: 150px;
        background: url(../../assets/images/background/astrid-background.png) no-repeat center;
        .text-astrid-title {
            font-weight: 700;
            font-size: 19.47px;
            line-height: 27.37px;
            color: #ffffff;
            text-align: center;
            font-family: "Unbounded", sans-serif;
            text-transform: uppercase;
            text-shadow: 0px 0px 3.32px 0px #ffffff;
        }
        .daily-countdown {
            margin-top: 60px !important;
            background: rgba(53, 28, 79, 1) !important;
            border: 1px solid rgba(53, 28, 79, 1) !important;
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 235px;
                z-index: 0;
                opacity: 0.8;
            }
            .round-winner {
                .nft-winners {
                    &.triangle-layout {
                        .common-winner {
                            .winner-info {
                                max-width: 140px;
                                margin: 0 auto;
                            }
                            &:first-child {
                                grid-column: span 2 / span 2;
                            }
                        }
                    }
                }
            }
        }
    }
    &.lunar-raffle {
        height: 85vh;
        padding-top: 150px;
        background: url(../../assets/images/background/lunar-background.png) no-repeat center;
        .text-lunar-title {
            font-weight: 700;
            font-size: 18.639px;
            line-height: 26.208px;
            color: #551f00;
            text-align: center;
            font-family: "Unbounded", sans-serif;
            text-transform: uppercase;
            text-shadow: 0px 0px 3.32px 0px #ffffff;
        }
        .text-lunar-small-text {
            font-weight: 700;
            font-size: 14.29px;
            line-height: 20.093px;
            color: #551f00;
            text-align: center;
            font-family: "Unbounded", sans-serif;
            text-transform: uppercase;
            text-shadow: 0px 0px 3.32px 0px #ffffff;
        }
        .daily-countdown {
            margin-top: 60px !important;
            background: rgba(53, 28, 79, 1) !important;
            border: 1px solid rgba(53, 28, 79, 1) !important;
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 235px;
                z-index: 0;
                opacity: 0.8;
            }
            .round-winner {
                .nft-winners {
                    &.triangle-layout {
                        .common-winner {
                            &.first {
                                .common-winner-border {
                                    width: 96px;
                                    height: 96px;
                                    .winner-beast {
                                    }
                                }
                            }
                            .winner-info {
                                max-width: 140px;
                                margin: 0 auto;
                            }
                            &:first-child {
                                grid-column: span 2 / span 2;
                            }
                        }
                    }
                }
            }
        }
    }
    &.christmas-raffle {
        height: 85vh;
        padding-top: 150px;
        background: url(../../assets/images/background/christmas-challenge-bg.png) no-repeat center;
        .weekly-raffle-content{
            margin: -50px 0 auto;
        }
        .text-christmas-title {
            font-weight: 700;
            font-size: 18.639px;
            line-height: 26.208px;
            color: #551f00;
            text-align: center;
            font-family: "Unbounded", sans-serif;
            text-transform: uppercase;
            text-shadow: 0px 0px 3.32px 0px #ffffff;
        }
        .text-christmas-small-text {
            font-weight: 700;
            font-size: 14.29px;
            line-height: 20.093px;
            color: #551f00;
            text-align: center;
            font-family: "Unbounded", sans-serif;
            text-transform: uppercase;
            text-shadow: 0px 0px 3.32px 0px #ffffff;
        }
        .daily-countdown {
           margin-top: 28px !important;
            background: rgba(53, 28, 79, 1) !important;
            border: 1px solid rgba(53, 28, 79, 1) !important;
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 235px;
                z-index: 0;
                opacity: 0.8;
            }
            .round-winner {
                .nft-winners {
                    &.triangle-layout {
                        .common-winner {
                            &.first {
                                .common-winner-border {
                                    width: 96px;
                                    height: 96px;
                                    .winner-beast {
                                    }
                                }
                            }
                            .winner-info {
                                max-width: 140px;
                                margin: 0 auto;
                            }
                            &:first-child {
                                grid-column: span 2 / span 2;
                            }
                        }
                    }
                }
            }
        }
    }
    &.halloween-raffle {
        height: 85vh;
        padding-top: 150px;
        background: url(../../assets/images/background/halloween-challenge-bg.png) no-repeat center;
        .weekly-raffle-content{
            margin: -50px 0 auto;
        }
        .text-halloween-title {
            font-weight: 700;
            font-size: 18.639px;
            line-height: 26.208px;
            color: #551f00;
            text-align: center;
            font-family: "Unbounded", sans-serif;
            text-transform: uppercase;
            text-shadow: 0px 0px 3.32px 0px #ffffff;
        }
        .text-halloween-small-text {
            font-weight: 700;
            font-size: 14.29px;
            line-height: 20.093px;
            color: #551f00;
            text-align: center;
            font-family: "Unbounded", sans-serif;
            text-transform: uppercase;
            text-shadow: 0px 0px 3.32px 0px #ffffff;
        }
        .daily-countdown {
           margin-top: 28px !important;
            background: rgba(53, 28, 79, 1) !important;
            border: 1px solid rgba(53, 28, 79, 1) !important;
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 235px;
                z-index: 0;
                opacity: 0.8;
            }
            .round-winner {
                .nft-winners {
                    &.triangle-layout {
                        .common-winner {
                            &.first {
                                .common-winner-border {
                                    width: 96px;
                                    height: 96px;
                                    .winner-beast {
                                    }
                                }
                            }
                            .winner-info {
                                max-width: 140px;
                                margin: 0 auto;
                            }
                            &:first-child {
                                grid-column: span 2 / span 2;
                            }
                        }
                    }
                }
            }
        }
    }
    &.valentine-raffle {
        height: 85vh;
        padding-top: 150px;
        background: url(../../assets/images/background/valentine-challenge-bg.png) no-repeat center;
        .weekly-raffle-content{
            margin: -50px 0 auto;
        }
     
        .daily-countdown {
           margin-top: 28px !important;
            background: rgba(53, 28, 79, 1) !important;
            border: 1px solid rgba(53, 28, 79, 1) !important;
        }
        .finished-round-container {
            &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 0;
                background: url(../../assets/images/background/apes-winner-aura.png) no-repeat center;
                width: 100%;
                height: 235px;
                z-index: 0;
                opacity: 0.8;
            }
            .round-winner {
                .nft-winners {
                    &.triangle-layout {
                        .common-winner {
                            &.first {
                                .common-winner-border {
                                    width: 96px;
                                    height: 96px;
                                    .winner-beast {
                                    }
                                }
                            }
                            .winner-info {
                                max-width: 140px;
                                margin: 0 auto;
                            }
                            &:first-child {
                                grid-column: span 2 / span 2;
                            }
                        }
                    }
                }
            }
        }
    }
    &.yuliverse-raffle {
        height: 60vh;
        padding-top: 150px;
        background: url(../../assets/images/background/yuliverse-background.png) no-repeat center;
        .daily-countdown {
            margin-top: 20px !important;
            background: rgba(53, 28, 79, 1) !important;
            border: 1px solid rgba(53, 28, 79, 1) !important;
        }
    }
    .weekly-raffle-banner {
        &.christmas-raffle{
            display:flex;
            justify-content: center;
            img{
                padding-top: 0;
            }
        }
        &.halloween-raffle{
            display:flex;
            justify-content: center;
            img{
                padding-top: 0;
            }
        }
        &.valentine-raffle{
            display:flex;
            justify-content: center;
            img{
                padding-top: 0;
            }
        }
        img {
            padding-top: 50px;
            user-select: none;
        }
    }
    .weekly-raffle-content {
        margin: auto 0;
        padding: 0 25px 15px;
        margin-top: -30px;
        // margin-top: -350px;
        // position: absolute;
    }
    .daily-countdown,
    .finished-round-container,
    .instruction-container {
        p,
        span {
            font-family: "Poppins", sans-serif;
        }
        background: rgba(53, 28, 79, 0.4);
        border: 1px solid rgba(53, 28, 79, 0.4);
        border-radius: 10px;
        padding: 20px;
    }
    .events {
        img {
            width: 100%;
            border-radius: 8px;
        }
        .lw-event,
        .daily-event {
            position: relative;
            .button {
                position: absolute;
                font-size: 11px;
                min-height: 26px;
                height: 26px;
                width: 120px;
                border-radius: 37px;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: -12px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .finished-round-container,
    .instruction-container {
        position: relative;
        margin: 50px 0;
        background-color: #351c4f;
        .floating-btn {
            top: 0px;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            pointer-events: none;
            img {
                transform: scale(1.2);
                user-select: none;
            }
        }
        &.exclusive {
            .floating-btn {
                img {
                    transform: scale(1.6);
                }
            }
        }
    }
    .finished-round-container {
        .current-round {
            // position: relative;
            .current-date {
                p {
                    margin-bottom: 0;
                }
            }
            .prev,
            .next {
                margin: 20px 30px 0;
                width: 40px;
                height: 40px;
                cursor: pointer;
                &.disabled {
                    cursor: not-allowed;
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }
        .winner-list {
            background: #21063c;
            padding: 5px 0px;
            border-radius: 10px;
            ul {
                padding: 0 15px;
                margin-bottom: 0;
                li {
                    margin: 0 0 10px;
                    color: #90829d;
                    width: 100%;
                    font-weight: 500;
                    line-height: 20px;
                    .winner-border {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    &.active {
                        padding: 1px;
                        margin-top: -3px;
                        margin-left: -5px;
                        border-radius: 5px;
                        color: #ffffff;
                        width: auto;
                        position: relative;
                        border: 0px solid transparent;
                        background-clip: padding-box;
                        background-image: linear-gradient(to right bottom, #61aebe, #bc3b99);
                        box-shadow: 0px 0px 10px 0px rgba(228, 0, 123, 0.3);
                        .winner-border {
                            width: 100%;
                            height: 100%;
                            background-color: #351c4f;
                            border-radius: 5px;
                            padding: 1px 4px;
                        }
                    }
                }
            }
        }
    }
    .instruction-container {
        margin-bottom: 0;
        .instruction-item {
            background: #21063c;
            padding: 15px;
            margin-top: 20px;
            border-radius: 10px;
            position: relative;
            .instruction-item-index {
                right: 15px;
                top: -10px;
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                font-size: 20px;
                text-align: center;
                line-height: 38px;
                border: 1px solid #f0489f;
                background-color: #f0489f;
            }
        }
    }
}

.text-pink-glow {
    color: #ffffff;
    margin-bottom: 10px;
    font-family: "Race Sport", sans-serif !important;
    text-shadow: rgb(228, 0, 123) 3px 0px 0px, rgb(228, 0, 123) 2.83487px 0.981584px 0px,
        rgb(228, 0, 123) 2.35766px 1.85511px 0px, rgb(228, 0, 123) 1.62091px 2.52441px 0px,
        rgb(228, 0, 123) 0.705713px 2.91581px 0px, rgb(228, 0, 123) -0.287171px 2.98622px 0px,
        rgb(228, 0, 123) -1.24844px 2.72789px 0px, rgb(228, 0, 123) -2.07227px 2.16926px 0px,
        rgb(228, 0, 123) -2.66798px 1.37182px 0px, rgb(228, 0, 123) -2.96998px 0.42336px 0px,
        rgb(228, 0, 123) -2.94502px -0.571704px 0px, rgb(228, 0, 123) -2.59586px -1.50383px 0px,
        rgb(228, 0, 123) -1.96093px -2.27041px 0px, rgb(228, 0, 123) -1.11013px -2.78704px 0px,
        rgb(228, 0, 123) -0.137119px -2.99686px 0px, rgb(228, 0, 123) 0.850987px -2.87677px 0px,
        rgb(228, 0, 123) 1.74541px -2.43999px 0px, rgb(228, 0, 123) 2.44769px -1.73459px 0px,
        rgb(228, 0, 123) 2.88051px -0.838247px 0px;
}
.text-gradient {
    color: #ff84dd;
    background-image: -webkit-linear-gradient(90deg, #ff84dd 0%, #ffffff 50%, #00f0ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(-2px 3px #1749ff);
}
.text-golden {
    background: linear-gradient(
        275.73deg,
        #e5b332 20.68%,
        #ffff9f 43.15%,
        #ecc247 60.67%,
        #ffffa0 80.02%,
        #edbe40 96.18%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.text-summer-fitsnap-title {
    text-transform: uppercase;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    font-family: "Unbounded", sans-serif;
    color: #fc71f6;
    background-image: -webkit-linear-gradient(-270deg, #fc71f6 28.37%, #719dfb 55.85%, #00c2ff 75.9%);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}
@media (min-width: 768px) {
    .weekly-raffle-container {
        height: calc(100vh + 100px);
        .weekly-raffle-content {
            margin-top: -100px;
        }
    }
}

@media (max-width: 376px) {
    .text-special-event {
        font-size: 14px !important;
    }
    .apes-event {
        .beast-reward {
            font-size: 18px !important;
        }
    }
    .weekly-raffle-container {
        &.welcome-raffle {
            padding-top: 0;
        }
        &.starfish-raffle {
            height: 110vh;
            .weekly-raffle-content {
                .weekly-raffle-banner-title {
                    margin-top: 220px;
                }
            }
        }
        .weekly-raffle-content {
            .weekly-raffle-banner-title {
                .beast-reward {
                    font-size: 16px !important;
                }
            }
        }
    }
}

