.ant-message {
    top: 50% !important;
    .ant-message-notice-content {
        border-radius: 30px;
        background-color: #000;
        font-weight: 400;
        color: #fff;
        text-transform: initial;
        .anticon {
            display: none;
        }
    }
}
