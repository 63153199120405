.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome {
    padding: 24px;
    padding-bottom: 60px;
    &::-webkit-scrollbar {
        display: none;
    }
    .welcome-header {
        .intro-text {
            margin-left: 10px;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            text-align: center;
            color: white;
            font-family: "Poppins", sans-serif;
            text-transform: none;
        }
        .overlay {
            width: 32px;
            height: 32px;
        }
    }
    .welcome-container {
        .welcome-step {
            margin-top: 0 !important;
            .welcome-step-header {
                width: 116px;
                height: 32px;
                border-radius: 7px;
                background: #a16bd8;
                box-shadow: 0px 0px 12px 0px rgba(134, 14, 255, 0.7), 0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset;
                color: white;
                line-height: 32px;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
    .lw-btn-back {
        .btn-skip {
            width: 100px;
            line-height: 44px;
            height: 44px;
            margin-top: -10px;
            background-color: #4ccbc9;
            color: #280748;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
        }
    }
}

@media (min-width: 450px) {
    .welcome {
        padding: 24px;
        padding-bottom: 60px;
        &::-webkit-scrollbar {
            display: none;
        }
        .welcome-header {
            .intro-text {
                flex: 1;
                font-weight: 600;
                line-height: 26px;
                text-align: center;
                color: white;
                font-size: 20px;
            }
            .overlay {
                width: 32px;
                height: 32px;
            }
        }
        .welcome-container {
            .welcome-step {
                margin-top: 0 !important;
                .welcome-step-header {
                    width: 140px;
                    height: 36px;
                    border-radius: 8px;
                    background: var(--Secondary-Color, #a16bd8);
                    box-shadow: 0px 0px 12px 0px rgba(134, 14, 255, 0.7), 0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset;
                    color: white;
                    line-height: 36px;
                    font-size: 17px;
                }
            }
        }
        .lw-btn-back {
            .btn-skip {
                width: 100px;
                line-height: 44px;
                height: 44px;
                margin-top: -10px;
                background-color: #4ccbc9;
                color: #280748;
                font-family: Poppins;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
            }
        }
    }
}
@media (min-width: 700px) {
    .welcome {
        padding: 24px;
        padding-bottom: 60px;
        &::-webkit-scrollbar {
            display: none;
        }
        .welcome-header {
            .intro-text {
                margin-left: 10px;
                font-weight: 600;
                line-height: 26px;
                text-align: center;
                color: white;
                font-size: 24px;
            }
            .overlay {
                width: 32px;
                height: 32px;
            }
        }
        .welcome-container {
            .welcome-step {
                margin-top: 0 !important;
                .welcome-step-header {
                    width: 200px;
                    height: 60px;
                    border-radius: 8px;
                    background: var(--Secondary-Color, #a16bd8);
                    box-shadow: 0px 0px 12px 0px rgba(134, 14, 255, 0.7), 0px -3px 0px 0px rgba(0, 0, 0, 0.25) inset;
                    color: white;
                    line-height: 60px;
                    font-size: 20px;
                }
            }
        }
        .lw-btn-back {
            .btn-skip {
                width: 100px;
                line-height: 44px;
                height: 44px;
                margin-top: -10px;
                background-color: #4ccbc9;
                color: #280748;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
            }
        }
    }
}

