.lw-container {
    background: url(../../assets/images/background/lw-bg.png) no-repeat center;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    position: fixed;

    &.beyond,
    &.paris-dot,
    &.polkadot {
        width: 100vw;
        .lw-text {
            margin-top: 40px;
        }
        .wheel-wrapper {
            margin: -80px auto 0 !important;
        }
        .lw-bottom {
            position: absolute;
            bottom: 0;
            background-color: #351c4f;
            box-shadow: 0px 10px 74px rgba(0, 0, 0, 0.49);
            border-radius: 32px 32px 0px 0px;
            padding: 15px 15px 20px;
            z-index: 100;
            width: 100%;
            .lw-spin-wrapper {
                .lw-code {
                    color: rgba(255, 255, 255, 0.7);
                    margin-bottom: 10px;
                    border-radius: 10px;
                    height: 50px;
                    padding-left: 10px;
                    font-size: 13px;
                    font-family: "Poppins", sans-serif;
                    background-color: #250e3a;
                    border: 1px solid #250e3a;
                }
                .btn-spin-wrapper {
                    top: 15px;
                    right: 17px;
                    img {
                        width: 118px;
                    }
                    a {
                        &:active {
                            transform: translateY(5px);
                        }
                    }
                }
            }
        }
    }
    &.beyond {
        background: url(../../assets/images/background/lw-beyond-bg.png) no-repeat center;
        background-size: cover;
    }
    &.paris-dot {
        background-size: cover !important;
        background: url(../../assets/images/background/lw-paris-dot-bg.png) no-repeat center;
    }
    &.polkadot {
        background-size: cover !important;
        background: url(../../assets/images/background/lw-polkadot-bg.png) no-repeat center;
    }

    &.show-keyboard {
        .lw-text {
            margin-top: -20px !important;
        }
        .lw-list-winners {
            opacity: 0;
        }
        .wheel-flex {
            height: calc(100vh - 100px);
        }
    }
    a,
    p,
    span {
        color: #ffffff;
    }
    p,
    span {
        font-family: "Poppins", sans-serif;
    }
    .lw-text {
        img {
            width: 65%;
            margin: 0px auto;
            padding-top: 50px;
            user-select: none;
        }
    }
    .lw-list-winners {
        position: relative;
        background: transparent;
        width: 250px;
        height: 50px;
        overflow: hidden;
        margin: 0 auto 10px;
        z-index: 100;

        .lw-winners-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            // animation: scroll 100s linear 2s infinite;
            .lw-winner {
                background: rgba(53, 28, 79, 0.4);
                border: 1px solid rgba(53, 28, 79, 0.4);
                border-radius: 40px;
                padding: 2px 4px 2px 4px;
                margin: 10px 0;
                display: inline-flex;
                p {
                    max-width: 200px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin: auto 0;
                }
                .winner-avatar {
                    position: relative;
                    .sub-avatar {
                        top: 8px;
                        right: -8px;
                        position: absolute;
                        img {
                            width: 18px;
                            height: 18px;
                            user-select: none;
                        }
                    }
                }
            }
        }
        @keyframes scroll {
            100% {
                transform: translateY(-100%);
            }
        }
    }

    .lw-button {
        .button-3d {
            position: relative;
            display: inline-block;
            margin: 20px 0 10px;
            border-radius: 10px;
            box-shadow: 0px 0px 44px #860eff;
        }

        .button-3d a {
            color: white;
            font-family: "Race Sport", sans-serif;
            font-weight: 400;
            font-size: 24px;
            text-align: center;
            text-decoration: none;
            background-color: #e4007b;
            display: flex;
            align-items: center;
            position: relative;
            padding: 10px 30px;
            cursor: pointer;

            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            text-shadow: 0px 1px 0px #000;
            filter: dropshadow(color=#000, offx=0px, offy=1px);
            -webkit-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.25);
            -moz-box-shadow: inset 0 -3pxpx 0 rgba(0, 0, 0, 0.25);
            box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.25);

            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            span {
                font-family: "Race Sport", sans-serif;
            }
            &:active {
                transform: translateY(5px);
                background-color: #8f004d;
            }
            &.disabled {
                cursor: not-allowed;
                pointer-events: none;
                transition: 0.5s ease-in-out;
            }
        }
    }
}

.lucky-wheel-modal-container {
    .ant-modal-content {
        border: none !important;
        box-shadow: none !important;
        background: transparent !important;
        &::before {
            content: none !important;
        }
        .ant-modal-body {
            padding: 0 !important;
        }
    }
}

.show {
    .lw-widget {
        opacity: 1;
        pointer-events: all;
    }
}
.hide {
    .lw-widget {
        opacity: 0;
        pointer-events: none;
    }
}
.lw-widget {
    top: 52px;
    left: 0;
    right: 0;
    position: absolute;
    transition: 0.5s all;
    padding: 0 24px;
    .lw-btn-back {
        cursor: pointer;
        img {
            transition: 0.3 ease-in-out;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .lw-mfr {
        background: rgba(40, 7, 72, 0.6);
        border: 2px solid rgba(228, 0, 123, 0.5);
        border-radius: 28px;
        padding: 3px 6px;
        span {
            font-family: "Poppins", sans-serif;
        }
    }

    .lw-history {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.wheel-wrapper {
    margin: 20px auto 0;
}

.wheel-flex {
    height: calc(100vh - 250px);
}

@media (min-width: 576px) {
    .lw-container {
        .lw-text {
            img {
                width: 60%;
            }
        }
    }
    .wheel-flex {
        height: calc(100vh - 350px);
    }
}

@media (max-width: 375.1px) {
    .lw-container {
        &.beyond,
        &.paris-dot,
        &.polkadot {
            .wheel-wrapper {
                margin: -30px auto 0 !important;
            }
            .lw-bottom {
                .lw-spin-wrapper {
                    .lw-code {
                        height: 40px;
                        min-height: 40px;
                    }
                    .btn-spin-wrapper {
                        img {
                            width: 94px;
                        }
                    }
                }
            }
        }

        &.show-keyboard {
            .lw-text {
                margin-top: -10px;
            }
        }
    }
}

#direction {
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    z-index: 50;
    margin: -5px auto;
    position: absolute;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 35px solid #4ccbc9;
    #direction-inner {
        top: -32px;
        left: -10px;
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
        border-width: 0px 8px 25px 0;
        border-color: transparent #8ee2e1 transparent transparent;
        transform: rotate(-4deg);
    }
}

#sub-direction {
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    z-index: 40;
    margin: 0px auto;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 38px solid #289795;
}

.swing-direction {
    #direction {
        animation: swingArrow 0.5s infinite;
        #direction-inner {
            animation: swingArrow 0.5s infinite;
        }
    }
    #sub-direction {
        animation: swingArrow 0.5s infinite;
    }
}

@keyframes swingArrow {
    0%,
    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    50% {
        transform: rotate(-12deg);
        -webkit-transform: rotate(-12deg);
    }
}

.wheel {
    width: 310px;
    height: 310px;
    z-index: 20;
    border-radius: 50%;
    border: 16px solid #ffb206;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 4px 52px 0px #9108a7;
    &::before {
        content: "";
        position: absolute;
        border: 8px solid rgba(70, 35, 104, 0.15);
        width: 280px;
        height: 283px;
        border-radius: 50%;
        z-index: 100;
    }
    #inner-wheel {
        width: 100%;
        height: 100%;
        // position: absolute;
        span,
        img {
            pointer-events: none;
            user-select: none;
        }
    }

    #spin {
        width: 59px;
        height: 59px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -27px;
        border-radius: 50%;
        box-shadow: 0px 5px 5px 4px rgba(0, 0, 0, 0.2), inset -7px -4px 0px -2px #ffb206, inset 0px 0px 0px 0px #ffb206;
        -moz-box-shadow: 0px 5px 5px 4px rgba(0, 0, 0, 0.2), inset -7px -4px 0px -2px #ffb206,
            inset 0px 0px 0px 0px #ffb206;
        -webkit-box-shadow: 0px 5px 5px 4px rgba(0, 0, 0, 0.2), inset -7px -4px 0px -2px #ffb206,
            inset 0px 0px 0px 0px #ffb206;
        -o-box-shadow: 0px 5px 5px 4px rgba(0, 0, 0, 0.2), inset -7px -4px 0px -2px #ffb206,
            inset 0px 0px 0px 0px #ffb206;
        z-index: 999;
        background: #ffed10;
        &:active {
            transform: translateY(4px);
        }
        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            transition: 0.5s ease-in-out;
        }
    }

    #spin:after {
        content: "";
        text-align: center;
        line-height: 68px;
        // color: #ccc;
        // text-shadow: 0 2px 0 #fff, 0 -2px 0 rgba(0, 0, 0, 0.3);
        position: relative;
        z-index: 1;
        width: 68px;
        height: 68px;
        display: block;
    }

    #spin:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 28px 20px;
        border-color: transparent transparent #ffed10 transparent;
        top: -12px;
        left: 14px;
        opacity: 0;
    }

    #inner-spin {
        width: 54px;
        height: 54px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -27px 0 0 -27px;
        border-radius: 50%;
        background: #ffed10;
        z-index: 1;
        box-shadow: inset 9px 7px 0px -5px #fff6b8, inset 0px 0px 0px 0px #fff6b8;
        -moz-box-shadow: inset 9px 7px 0px -5px #fff6b8, inset 0px 0px 0px 0px #fff6b8;
        -webkit-box-shadow: inset 9px 7px 0px -5px #fff6b8, inset 0px 0px 0px 0px #fff6b8;
        -o-box-shadow: inset 9px 7px 0px -5px #fff6b8, inset 0px 0px 0px 0px #fff6b8;
    }
}

.beyond,
.paris-dot {
    .wheel {
        border: 16px solid #e7087b;
    }
}

.wheeldotsround {
    z-index: 19;
    // border: 15px solid #ffb206;
    position: relative;
    width: 310px;
    height: 310px;
}
.wheeldotsround .wheeldots {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ffed10;
    position: absolute;
    z-index: 25;
}

.flicker {
    &.wheeldotsround .wheeldots:nth-child(odd) {
        animation: flickerDots 0.5s infinite;
        -webkit-animation: flickerDots 0.5s infinite;
    }

    &.wheeldotsround .wheeldots:nth-child(even) {
        animation: flickerDots 1s infinite;
        -webkit-animation: flickerDots 1s infinite;
    }
}

@keyframes flickerDots {
    0% {
        background: #ffed10;
    }
    50% {
        background: #ff8400;
    }
    100% {
        background: #ffed10;
    }
}
.wheeldotsround .wheeldots:nth-child(1) {
    right: 27%;
    top: 6.5%;
}
.wheeldotsround .wheeldots:nth-child(2) {
    right: 11%;
    top: 18.8%;
}
.wheeldotsround .wheeldots:nth-child(3) {
    right: 4%;
    top: 33%;
}
.wheeldotsround .wheeldots:nth-child(4) {
    right: 4px;
    top: 50%;
}
.wheeldotsround .wheeldots:nth-child(5) {
    right: 5.2%;
    bottom: 30%;
}
.wheeldotsround .wheeldots:nth-child(6) {
    right: 16%;
    bottom: 14.5%;
}

.wheeldotsround .wheeldots:nth-child(7) {
    bottom: 14px;
    right: 32%;
}
.wheeldotsround .wheeldots:nth-child(8) {
    left: 49%;
    bottom: 1%;
}
.wheeldotsround .wheeldots:nth-child(9) {
    left: 31%;
    bottom: 5%;
}
.wheeldotsround .wheeldots:nth-child(10) {
    left: 16%;
    bottom: 15%;
}
.wheeldotsround .wheeldots:nth-child(11) {
    left: 6%;
    bottom: 30%;
}
.wheeldotsround .wheeldots:nth-child(12) {
    left: 1.5%;
    top: 50%;
}
.wheeldotsround .wheeldots:nth-child(13) {
    left: 4%;
    top: 32%;
}
.wheeldotsround .wheeldots:nth-child(14) {
    left: 12%;
    top: 18%;
}
.wheeldotsround .wheeldots:nth-child(15) {
    left: 25%;
    top: 7.5%;
}

.wheelsquaresround {
    position: relative;
    #spin-beyond {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 40;
        img {
            width: 110px;
        }
    }
    .wheelsquares-circle {
        width: 295px;
        height: 298px;
        top: 6px;
        left: 7px;
        position: absolute;
        border-radius: 50%;
        border: 1px solid #ffffff;
        z-index: 25;
    }
    .wheelsquares {
        width: 10px;
        height: 10px;
        background-color: #ffffff;
        position: absolute;
        z-index: 25;
        &:nth-child(1) {
            right: 27%;
            top: 6.5%;
        }
        &:nth-child(2) {
            right: 31%;
            top: 4.2%;
            rotate: (22deg);
        }
        &:nth-child(3) {
            right: 18%;
            top: 12%;
            rotate: (40deg);
        }
        &:nth-child(4) {
            right: 24px;
            top: 24%;
            rotate: (58deg);
        }
        &:nth-child(5) {
            right: 7px;
            top: 38%;
            rotate: (77deg);
        }
        &:nth-child(6) {
            right: 1.3%;
            bottom: 44.5%;
            rotate: (7deg);
        }

        &:nth-child(7) {
            bottom: 30%;
            right: 4.8%;
            rotate: (22deg);
        }
        &:nth-child(8) {
            bottom: 16%;
            right: 14%;
            rotate: (40deg);
        }
        &:nth-child(9) {
            bottom: 5%;
            right: 28%;
            rotate: 62deg;
        }
        &:nth-child(10) {
            left: 50%;
            bottom: 1%;
        }
        &:nth-child(11) {
            left: 32%;
            bottom: 3.5%;
            rotate: 25deg;
        }
        &:nth-child(12) {
            left: 15%;
            bottom: 14%;
            rotate: 40deg;
        }
        &:nth-child(13) {
            left: 5%;
            bottom: 28%;
            rotate: -18deg;
        }
        &:nth-child(14) {
            left: 1%;
            bottom: 43%;
            rotate: -5deg;
        }
        &:nth-child(15) {
            left: 2%;
            top: 39%;
            rotate: 20deg;
        }
        &:nth-child(16) {
            top: 25%;
            left: 7%;
            rotate: 25deg;
        }
        &:nth-child(17) {
            top: 14%;
            left: 16%;
            rotate: 50deg;
        }
        &:nth-child(18) {
            top: 5%;
            left: 30%;
            rotate: -25deg;
        }
    }
}

.wheel {
    .sec {
        height: 50%;
        width: 100px;
        position: absolute;
        clip-path: polygon(100% 0, 50% 100%, 0 0);
        transform: translateX(-50%);
        transform-origin: bottom;
        text-align: center;
        display: block;
        font-size: 20px;
        font-weight: bold;

        color: #fff;
        left: 89px;
        img {
            margin: 15px auto 10px;
            width: 36px;
            height: 36px;
            user-select: none;
        }
        span {
            font-size: 16px;
            line-height: 28px;
            font-weight: 400;
            -webkit-text-stroke: 1px #351c4f;
            -webkit-text-fill-color: white;
            font-family: "Race Sport", sans-serif;
        }
    }
    &.wheel-11 {
        .sec {
            width: 90px;
            left: 94px;
        }
    }
    &.wheel-12 {
        .sec {
            width: 82px;
            left: 98px;
        }
    }
}

.wheel-9 .sec:nth-child(1) {
    background-color: #ffcaf0;
    transform: rotate(0);
}
.wheel-9 .sec:nth-child(2) {
    background-color: #f0489f;
    transform: rotate(40deg);
}
.wheel-9 .sec:nth-child(3) {
    background-color: #ffcaf0;
    transform: rotate(80deg);
}
.wheel-9 .sec:nth-child(4) {
    background-color: #ffffff;
    transform: rotate(120deg);
}
.wheel-9 .sec:nth-child(5) {
    background-color: #f0489f;
    transform: rotate(160deg);
}
.wheel-9 .sec:nth-child(6) {
    background-color: #ffffff;
    transform: rotate(200deg);
}
.wheel-9 .sec:nth-child(7) {
    background-color: #ffcaf0;
    transform: rotate(240deg);
}
.wheel-9 .sec:nth-child(8) {
    background-color: #ffffff;
    transform: rotate(280deg);
}
.wheel-9 .sec:nth-child(9) {
    background-color: #f0489f;
    transform: rotate(320deg);
}

.wheel-10 .sec:nth-child(1) {
    background-color: #ffcaf0;
    transform: rotate(0);
}
.wheel-10 .sec:nth-child(2) {
    background-color: #f0489f;
    transform: rotate(36deg);
}
.wheel-10 .sec:nth-child(3) {
    background-color: #ffcaf0;
    transform: rotate(72deg);
}
.wheel-10 .sec:nth-child(4) {
    background-color: #ffffff;
    transform: rotate(108deg);
}
.wheel-10 .sec:nth-child(5) {
    background-color: #f0489f;
    transform: rotate(144deg);
}
.wheel-10 .sec:nth-child(6) {
    background-color: #ffffff;
    transform: rotate(180deg);
}
.wheel-10 .sec:nth-child(7) {
    background-color: #ffcaf0;
    transform: rotate(216deg);
}
.wheel-10 .sec:nth-child(8) {
    background-color: #ffffff;
    transform: rotate(252deg);
}
.wheel-10 .sec:nth-child(9) {
    background-color: #f0489f;
    transform: rotate(288deg);
}
.wheel-10 .sec:nth-child(10) {
    background-color: #ffffff;
    transform: rotate(324deg);
}

.wheel-11 .sec:nth-child(1) {
    background-color: #ffcaf0;
    transform: rotate(0);
}
.wheel-11 .sec:nth-child(2) {
    background-color: #f0489f;
    transform: rotate(32.7deg);
}
.wheel-11 .sec:nth-child(3) {
    background-color: #ffcaf0;
    transform: rotate(65.4deg);
}
.wheel-11 .sec:nth-child(4) {
    background-color: #ffffff;
    transform: rotate(98.1deg);
}
.wheel-11 .sec:nth-child(5) {
    background-color: #f0489f;
    transform: rotate(130.8deg);
}
.wheel-11 .sec:nth-child(6) {
    background-color: #ffffff;
    transform: rotate(163.5deg);
}
.wheel-11 .sec:nth-child(7) {
    background-color: #ffcaf0;
    transform: rotate(196.2deg);
}
.wheel-11 .sec:nth-child(8) {
    background-color: #ffffff;
    transform: rotate(228.9deg);
}
.wheel-11 .sec:nth-child(9) {
    background-color: #f0489f;
    transform: rotate(261.6deg);
}
.wheel-11 .sec:nth-child(10) {
    background-color: #ffffff;
    transform: rotate(294.3deg);
}
.wheel-11 .sec:nth-child(11) {
    background-color: #f0489f;
    transform: rotate(327deg);
}

.wheel-12 .sec:nth-child(1) {
    background-color: #ffcaf0;
    transform: rotate(0);
}
.wheel-12 .sec:nth-child(2) {
    background-color: #f0489f;
    transform: rotate(30deg);
}
.wheel-12 .sec:nth-child(3) {
    background-color: #ffcaf0;
    transform: rotate(60deg);
}
.wheel-12 .sec:nth-child(4) {
    background-color: #ffffff;
    transform: rotate(90deg);
}
.wheel-12 .sec:nth-child(5) {
    background-color: #f0489f;
    transform: rotate(120deg);
}
.wheel-12 .sec:nth-child(6) {
    background-color: #ffffff;
    transform: rotate(150deg);
}
.wheel-12 .sec:nth-child(7) {
    background-color: #ffcaf0;
    transform: rotate(180deg);
}
.wheel-12 .sec:nth-child(8) {
    background-color: #ffffff;
    transform: rotate(210deg);
}
.wheel-12 .sec:nth-child(9) {
    background-color: #f0489f;
    transform: rotate(240deg);
}
.wheel-12 .sec:nth-child(10) {
    background-color: #ffffff;
    transform: rotate(270deg);
}
.wheel-12 .sec:nth-child(11) {
    background-color: #f0489f;
    transform: rotate(300deg);
}
.wheel-12 .sec:nth-child(12) {
    background-color: #ffffff;
    transform: rotate(330deg);
}
.arrow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
}
.arrow::before {
    content: "\1F817";
    font-size: 50px;
}
.ant-avatar {
    border: 1px #fff solid;
}
.nonesense-block {
    margin-top: 20px;
}
